import { useContext, computed } from '@nuxtjs/composition-api'
import { LocaleObject } from '@nuxtjs/i18n/types'

export const useLanguage = () => {
  const { i18n } = useContext()

  const languageCodeOptions = computed(() => {
    const locales = i18n.locales as LocaleObject[]

    return locales.map((locale) => ({
      label: locale.name,
      value: locale.code?.toUpperCase(),
    }))
  })

  return {
    languageCodeOptions,
    currentLocale: computed(() => i18n.locale?.toUpperCase())
  }
}
