var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"inline-flex flex-col align-middle select-none",class:[
    {
      'opacity-75 cursor-not-allowed': _vm.disabled,
      'w-full': _vm.block,
    } ]},[_c('div',{staticClass:"flex flex-1",class:[
      {
        'items-center': _vm.alignCenter,
      } ]},[_c('span',{staticClass:"flex-shrink-0 flex items-center justify-center border rounded-full w-15px h-15px",class:[
        {
          'border-pfm-grey-400 ': _vm.status === 'default',
          'border-pfm-error': _vm.status === 'error',
          'bg-black bg-opacity-10 opacity-60': _vm.variant === 'default',
          'bg-white': _vm.variant === 'primary',
          hidden: _vm.isRadioButton,
        } ]},[_c('input',_vm._b({staticClass:"sr-only",attrs:{"type":"radio","disabled":_vm.disabled},domProps:{"value":_vm.value,"checked":_vm.isChecked},on:{"click":_vm.onChange}},'input',_vm.$attrs,false)),_vm._v(" "),(_vm.isChecked && !_vm.disabled)?_c('span',{staticClass:"w-9px h-9px block rounded-full bg-pfm-primary"}):_vm._e()]),_vm._v(" "),(_vm.slots.default || _vm.label)?_c('div',{staticClass:"flex-1",class:[
        _vm.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        _vm.isRadioButton ? 'h-full' : 'inline-block px-1' ]},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.label))]},null,{ isChecked: _vm.isChecked })],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }